import {
  BufferGeometry,
  Color, ImageUtils,
  Light, LineDashedMaterial,
  Mesh, MeshLambertMaterial, MeshNormalMaterial,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Object3D,
  PointLight,
  Scene, SpriteMaterial, TextureLoader
} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {ANIMATION_FRAME} from "../utils/constants";
import TWEEN from "@tweenjs/tween.js";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {type} from "os";
import {MeshStandardNodeMaterial} from "three/examples/jsm/nodes/materials/MeshStandardNodeMaterial";

export class Machine {
  private loader = new GLTFLoader()
  textureLoader = new TextureLoader()
  private dracoLoader = new DRACOLoader()
  private bulbs: Mesh<BufferGeometry, MeshStandardMaterial>[] = []
  private currBulbLight: PointLight | null = null
  private currBulbIdx = 0
  frameMesh: Mesh<BufferGeometry, MeshStandardMaterial> | null = null

  constructor(private scene: Scene) {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/examples/js/libs/draco/')
    this.loader.setDRACOLoader(dracoLoader)
  }

  async init(colors: string[]) {
    const [model] = await Promise.all([
      this.getModel(),
      this.getLights()
    ])
    if (model) {
      this.scene.add(model.scene)
    }
    this.scene.traverse(node => {
      if (node instanceof Mesh) {
        if (['Frame', 'Back'].includes(node.name)) {
          // const material = node.material.clone() as MeshStandardMaterial
          // const material = new MeshStandardMaterial({ metalness: 1})
          const material = new MeshPhongMaterial({ shininess: 50 })
          material.color.set(colors[0]) // 0x6E3EF4
          node.material = material
          if (node.name === 'Back') {
            // const material = new SpriteMaterial({ map: this.textureLoader.load('images/wiply-w.png') })
            // node.material = material
            // material.emissiveMap = this.textureLoader.load('images/wiply-w.png')
          }
        } else if (node.name.startsWith('Lamp')) {
          // const material = new MeshNormalMaterial()
          const material = new MeshPhongMaterial({ shininess: 1000 })
          // node.geometry.com
          // material.shininess
          // material.opacity = .5
          node.material = material
          // const material = node.material.clone() as MeshStandardMaterial
          material.color.set(colors[1]) // 0x6E3EF4
          // node.material = material
        }
      }
    })
    // this.scene.traverse(node => {
    //   const obj = node as Mesh<BufferGeometry, MeshStandardMaterial>
    //   if (obj.name.startsWith('Lamp')) {
    //     if (obj.position.y < 1.8 && obj.position.y > -1.8) {
    //       // Model frame is curved so offset bulbs.
    //       obj.position.setZ(.1)
    //     }
    //     // this.addBulbLight(obj)
    //     this.bulbs.push(obj)
    //   } else if (obj.name === 'Frame') {
    //     this.frameMesh = obj
    //   }
    // })
    // this.animateBulbsLights()
    return this
  }

  async getModel() {
    try {
      const model = await this.loader.loadAsync('models/frame-flat.gltf')
      return model
    } catch (e) {
      console.log(e)
      return null
    }
  }

  async getLights() {
    try {
      const model = await this.loader.loadAsync('models/lights.gltf')
      return model
    } catch (e) {
      console.log(e)
      return null
    }
  }

  setFrameColor(color: Color) {
    this.frameMesh?.material?.color.set(color)
  }

  setBulbsColor(color: Color) {
    this.bulbs.map(b => b.material.color.set(color))
  }

  addBulbLight(bulb: Mesh<BufferGeometry, MeshStandardMaterial>) {
    // Unset emmission
    bulb.material.transparent = true
    bulb.material.emissive.set(0xFF83E8)
    const light = new PointLight(0xFF83E8, .2, .2)
    light.power = 5
    light.position.set(bulb.position.x, bulb.position.y, bulb.position.z)
    this.scene.add(light)
    // bulb.material.transparent = true
    // //this.frameMesh?.material.color
    // const light = new PointLight(0xffffff)
    // light.intensity = .5
    // light.power = 2
    // const { x, y, z } = bulb.position
    // light.position.set(x, y, z + .03)
    // this.scene.add(light)
    // return light
  }

  animateBulbsLights() {
    let lights = []
    new TWEEN.Tween({})
      .duration(200)
      .repeat(Infinity)
      .onRepeat(() => {
        if (lights.length) {
          lights.map(l => l.parent.remove(l))
          lights = []
        } else {
          lights = this.bulbs.map(bulb => this.addBulbLight(bulb))
        }
      })
      .start()
    // let start = 0
    // this.scene.addEventListener(ANIMATION_FRAME, async (e) => {
    //   if (e.frame - start > 50) {
    //     start = e.frame
    //     try {
    //       const obj = this.bulbs[this.currBulbIdx]
    //       const light = new PointLight(0xFFFF99)
    //       //obj.material.emissive
    //       light.intensity = 1
    //       light.power = 20
    //       const { x, y, z } = obj.position
    //       light.position.set(x, y, z + .03)
    //       this.scene.add(light)
    //       this.currBulbIdx = this.currBulbIdx > this.bulbs.length - 2 ? 0 : this.currBulbIdx + 1
    //       this.currBulbLight?.parent?.remove(this.currBulbLight)
    //       this.currBulbLight = light
    //     } catch (e) {
    //       console.log(e)
    //     }
    //   }
    //   // console.log(e)
    // })
    // return setInterval(() => {
    //   try {
    //     const obj = this.bulbs[this.currBulbIdx]
    //     const light = new PointLight(obj.material.emissive)
    //     const { x, y, z } = obj.position
    //     light.position.set(x, y, z + .1)
    //     this.scene.add(light)
    //     this.currBulbIdx = this.currBulbIdx > this.bulbs.length - 2 ? 0 : this.currBulbIdx + 1
    //     this.currBulbLight?.parent?.remove(this.currBulbLight)
    //     this.currBulbLight = light
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }, 50)
  }
}
