import {BufferGeometry, Mesh, MeshPhongMaterial, MeshStandardMaterial, Object3D, Vector3} from "three";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import {loadGLTF} from "../utils/gltf-loader";
import TWEEN from "@tweenjs/tween.js";

export abstract class BaseModel {
  model: GLTF | null;
  isRemoveShapeInProgress = false

  // abstract create(position: Vector3): Mesh<BufferGeometry, MeshStandardMaterial>

  create({ x, y, z }: Vector3): Mesh<BufferGeometry, MeshStandardMaterial> {
    let mesh = new Mesh<BufferGeometry, MeshPhongMaterial>()
    if (this.model) {
      this.model?.scene.traverse(node => {
        const material = new MeshPhongMaterial()
        // const obj = node as Mesh<BufferGeometry, MeshStandardMaterial>
        mesh = node as Mesh<BufferGeometry, MeshPhongMaterial>
        mesh.material = material
        material.shininess = 10
        // material.color = new Color()
      })
      mesh.position.set(x, y, z)
      mesh.material.emissive.set(0x000000)
    }
    return mesh
  }

  async loadModel(path: string) {
    this.model = await loadGLTF(path)
  }

  remove(shape: Object3D, onRemoved: (shape: Object3D) => void) {
    const tween = new TWEEN.Tween({scale: shape.scale.x})
    tween.to({scale: shape.scale.x * .1}, 200)
      .easing(TWEEN.Easing.Exponential.In)
      .onUpdate(({scale}) => {
        shape.scale.set(scale, scale, scale);
      })
      .onComplete(() => {
        shape.parent?.remove(shape)
        onRemoved(shape)
      })
      .start();
    this.isRemoveShapeInProgress = false
  }

  explode() {

  }
}
