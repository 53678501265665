import {
  Camera,
  Group,
  Object3D,
  Raycaster,
  Scene,
  Vector2,
  Vector3,
  WebGLRenderer,
  Audio,
  Color,
  TextureLoader,
  MeshLambertMaterial,
  PlaneGeometry,
  Mesh,
  PointLight,
  Sphere,
  BufferGeometry,
  MeshStandardMaterial, BoxGeometry, SphereGeometry
} from "three";
import {Machine} from "./machine";
import {StarModel} from "../models/star.model";
import {DECREMENT_LIVES, INCREMENT_SCORE} from "../utils/constants";
import {BallModel} from "../models/ball.model";
import {BaseModel} from "../models/base.model";
import {SquareModel} from "../models/square.model";
import {AudioLoader} from "../utils/audio-loader";
import TWEEN, {Tween} from "@tweenjs/tween.js";
import {GAME_OPTIONS} from "../index";
import {Coupon} from "./coupon";
import {MeshStandardNodeMaterial} from "three/examples/jsm/nodes/materials/MeshStandardNodeMaterial";

const DEFAULT_MAX_SHAPES = 10

class Model extends BaseModel {
  constructor() {
    super();
  }
}

export class MainScene {
  private machine: Machine
  private shapes: Group
  private star: StarModel
  private ball: BallModel
  private square: SquareModel
  private models: Model[]
  private popSound: Audio<GainNode> | null;
  private shapesAnimationsInterval: Tween<any>
  private shapesAnimations: Tween<any>[]
  private shapeColor: Color;
  private isRemoveShapeInProgress = false
  private coupons: Coupon[];
  private score: number;
  private maxScore: any;
  private pointerPosition = new Vector2()
  private raycaster = new Raycaster()
  private trail = new Group();

  constructor(
    private scene: Scene,
    private renderer: WebGLRenderer,
    private camera: Camera,
    private options: typeof GAME_OPTIONS) {
    this.machine = new Machine(this.scene)
    this.star = new StarModel()
    this.ball = new BallModel()
    this.square = new SquareModel()
    this.models = [this.star, this.ball, this.square]
  }

  async init() {
    await this.machine.init(this.options.colors)
    // this.machine.animateBulbsLights()
    await this.star.loadModel('star.gltf')
    await this.ball.loadModel('ball.gltf')
    await this.square.loadModel('square.gltf')
    this.popSound = await AudioLoader.loadAudio('audio/pop.mp3')

    this.shapes = new Group()
    this.scene.add(this.shapes)
    this.coupons = this.addCoupons(this.options.coupons)
    this.addScoreGuage(this.coupons)

    // this.removeShapeOnClickOrTap()

    this.shapesAnimations = []
    this.setColors()

    this.setupPointerEvents()
    this.animate()
    this.createMouseTrail()
  }

  animate() {
    const tween = new Tween({value: 0})
    tween.repeat(Infinity)
    tween.to({ value: 1 })
    tween.duration(100)
    tween.onUpdate(() => {
      this.onAnimationFrame()
    })
    tween.start()
  }

  onAnimationFrame() {
    // console.log(this.pointerPosition)
    this.checkForPointerCollision(this.pointerPosition)
    // this.mouseLight.position.set(this.pointerPosition.x, this.pointerPosition.y, 0)
    // this.mouseLight.position.
    // console.log(this.mouseLight.position)
    // const ball = this.shapes.getObjectByName('Sphere')
    // const shape = ball?.clone()
    // shape?.position.set(this.pointerPosition.x, this.pointerPosition.y, 0)
    // // const shape = this.ball.create(new Vector3(this.pointerPosition.x, this.pointerPosition.y, 0))
    // const particles = new Array(10).fill(null).map(() => shape.clone())
    // particles.forEach(p => this.scene.add(p))
  }

  start() {
    this.shapesAnimationsInterval = new TWEEN.Tween({})
      .repeat(Infinity)
      .duration(this.options.gameSpeed)
      .onRepeat(() => {
        this.shapesAnimations.push(this.animateShape())
      })
      .start()
  }

  animateShape() {
    const shape = this.addShape(this.models[Math.floor(Math.random() * this.models.length)])
    this.shapes.add(shape)
    return new TWEEN.Tween({y: 1.6})
      .to({y: -1.5}, 1000 + Math.random() * 500)
      .onUpdate((e) => {
        shape.position.y = e.y
        shape.rotation.x += Math.random() * .1
      })
      .onComplete(() => {
        if (this.scene.getObjectById(shape.id)) {
          this.removeShape(shape, () => {
            this.scene.dispatchEvent({type: DECREMENT_LIVES})
          })
        }
      })
      .onStop(() =>  this.removeShape(shape, () => {}))
      .start()
  }

  stopAnimations () {
    this.shapesAnimations.map(s => s.stop())
    this.shapesAnimationsInterval.stop()
  }

  // private async addShape() {
  //   const shape = await this.addStar()
  //   this.shapes.push(shape)
  // }

  private addShape(shape: BaseModel) {
    // const minX = this.machine.frameMesh?.geometry.boundingBox?.min.x || 0
    // const maxX = this.machine.frameMesh?.geometry.boundingBox?.max.x || 0
    const minX = -.9
    const maxX = .9
    const mesh = shape.create(new Vector3(Math.random() * (maxX - minX) - ((maxX - minX) / 2), 1.6, 0))
    mesh.material.color.set(this.shapeColor)
    return mesh.clone()
  }

  private removeShape(shape: Object3D, removed: (shape: Object3D) => void) {
    const particles = new Array(10).fill(null).map(() => shape.clone())
    shape.parent?.remove(shape)
    removed(shape)
    const scale = .2
    for (let particle of particles) {
      particle.scale.set(particle.scale.x * scale, particle.scale.y * scale, particle.scale.z * scale)
      this.scene.add(particle)
      const tween = new TWEEN.Tween({
        position: particle.position,
        rotation: particle.rotation,
        scale: particle.scale.x,
      })
      tween.to({
        position: {
          x: particle.position.x + .4 * (Math.random() - .5),
          y: particle.position.y + .4 * (Math.random() - .5),
          z: particle.position.z + .4 * (Math.random() - .5)
        },
        rotation: {
          x: particle.rotation.x * Math.random(),
          y: particle.rotation.y * Math.random(),
          z: particle.rotation.z * Math.random()
        },
        scale: 0
      }, 500)
        .easing(TWEEN.Easing.Linear.None)
        .onUpdate(({ position, rotation, scale }) => {
          particle.position.set(position.x , position.y, position.z)
          particle.rotation.set(rotation.x, rotation.y, rotation.z)
          particle.scale.set(scale, scale, scale)
          // shape.scale.set(scale, scale, scale);
        })
        .onComplete(() => {
          particle.parent?.remove(particle)
          // particle.parent?.remove(particle)
          // removed(shape)
        })
        .start();
    }

    // tween.to({scale: shape.scale.x * .1}, 200)
    //   .easing(TWEEN.Easing.Exponential.In)
    //   .onUpdate(({scale}) => {
    //     shape.scale.set(scale, scale, scale);
    //   })
    //   .onComplete(() => {
    //     shape.parent?.remove(shape)
    //     removed(shape)
    //   })
    //   .start();
    this.isRemoveShapeInProgress = false
    // shape.parent?.remove(shape)
  }

  setColors() {
    // TODO: Create Singleton ColorService
    const color1 = new Color(this.options.colors[0])
    const color2 = new Color(this.options.colors.length > 1 ? this.options.colors[1] : color1)
    this.shapeColor = color2
    this.machine.setFrameColor(color1)
    this.machine.setBulbsColor(color2)
    // this.shapes
    // setInterval(() => {
    //   this.shapes.children.map(s => {
    //     if (s instanceof Mesh) {
    //       const randomColor = Math.floor(Math.random()*16777215).toString(16);
    //       // console.log(s.material)
    //       // (s as Mesh<BufferGeometry, MeshStandardMaterial>).material.emissive.set('#' + randomColor)
    //     }
    //   })
    // }, 500)
  }

  checkForPointerCollision (pointer: { x: number, y: number }) {
    const mouse = new Vector2()
    mouse.x = (pointer.x / this.renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -(pointer.y / this.renderer.domElement.clientHeight) * 2 + 1;

    this.raycaster.setFromCamera(mouse, this.camera);

    const intersects = this.raycaster.intersectObjects([...this.shapes.children]);

    if (intersects.length > 0) {
      console.log('INTERSECTS')
      // User hit shape
      if (this.popSound?.isPlaying) {
        this.popSound.stop()
      }
      this.popSound?.play()
      this.removeShape(intersects[0].object, shape => {
        this.scene.dispatchEvent({type: INCREMENT_SCORE})
        // this.scene.dispatchEvent({type: INCREMENT_SCORE})
        // this.isRemoveShapeInProgress = false
      })
      // console.log('!')
      // intersects[0].object.parent?.remove(intersects[0].object)
    } else {
      this.isRemoveShapeInProgress = false
    }
  }

  removeShapeOnClickOrTap() {
    const raycaster = new Raycaster()
    const mouse = new Vector2();

    const checkForPointerCollision = (pointer: { x: number, y: number }) => {
      mouse.x = (pointer.x / this.renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -(pointer.y / this.renderer.domElement.clientHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, this.camera);

      const intersects = raycaster.intersectObjects([...this.shapes.children]);

      if (intersects.length > 0) {
        console.log('INTERSECTS')
        // User hit shape
        if (this.popSound?.isPlaying) {
          this.popSound.stop()
        }
        this.popSound?.play()
        this.removeShape(intersects[0].object, shape => {
          this.scene.dispatchEvent({type: INCREMENT_SCORE})
          // this.scene.dispatchEvent({type: INCREMENT_SCORE})
          // this.isRemoveShapeInProgress = false
        })
        // console.log('!')
        // intersects[0].object.parent?.remove(intersects[0].object)
      } else {
        this.isRemoveShapeInProgress = false
      }
    }

    // document.body.addEventListener('touchstart', event => {
    //   console.log('TOUCH_START')
    //   event.preventDefault();
    //   const x = event.touches[0].clientX
    //   const y = event.touches[0].clientY
    //   if (this.isTouchEventInProgress) return;
    //   checkForPointerCollision({x, y})
    //   this.isTouchEventInProgress = true
    // })

    // document.body.addEventListener('touchend', () => this.isTouchEventInProgress = false)
    // document.body.addEventListener('touchcancel', () => this.isTouchEventInProgress = false)
    // document.addEventListener('touchmove')

    // document.body.addEventListener('mousemove', event => {
    //   console.log('CLICK')
    //   event.preventDefault();
    //   const x = event.clientX
    //   const y = event.clientY
    //   // if (this.isRemoveShapeInProgress) return;
    //   this.isRemoveShapeInProgress = true
    //   checkForPointerCollision({x, y})
    // })
  }

  private addCoupons(coupons: Array<typeof GAME_OPTIONS.coupons[0]>) {
    const coupon = new Coupon(this.scene)
    const mesh = coupon.init(this.options?.colors?.[0], this.options.coupons[0].gameImgUrl)
    mesh.geometry.computeBoundingBox()
    // if (i > 0) {
      coupon.setPosition(new Vector2(
        mesh.position.x + (mesh.geometry.boundingBox?.max?.x || 0) * 0,
        mesh.position.y
      ))
    // }
    coupon.setTexture(coupons[0].gameImgUrl)
    return [coupon]
    return coupons.map((c, i) => {

      // const coupon = new Coupon(this.scene)
      // const mesh = coupon.init(this.options?.colors?.[0], this.options.coupons[i].gameImgUrl)
      // mesh.geometry.computeBoundingBox()
      // if (i > 0) {
      //   coupon.setPosition(new Vector2(
      //     mesh.position.x + (mesh.geometry.boundingBox?.max?.x || 0) * i,
      //     mesh.position.y
      //   ))
      // }
      // coupon.setTexture(c.gameImgUrl)
      // return coupon
    })
  }

  private addScoreGuage(coupons: Coupon[]) {
    // const firstCoupon = coupons.find(() => true)
    // const y = firstCoupon
    // const height = coupons.find(() => true)?.mesh.geometry.boundingBox.min.y
  }

  setScore(score: number, maxScore: number) {
    this.score = score
    this.maxScore = maxScore
    this.onScoreUpdate()
  }

  onScoreUpdate() {
    const currentCouponIndex = Math.floor(this.score / this.maxScore * this.coupons.length)
    this.coupons.forEach((coupon, i) => {
      if (i < currentCouponIndex) {
        // coupon.stopColorAnimation()
        coupon.setColor(coupon.enabledColor)
      } else if (i === currentCouponIndex) {
        // const animation = coupon.animateColor()
        // animation.onStop(() => {
        //   console.log('!!@#')
        //   coupon.setColor(coupon.enabledColor)
        // })
      } else {
        coupon.setColor(coupon.disabledColor)
      }
    })
    // this.coupons[currentCouponIndex].animateColor()
  }

  // private incrementScore() {
  //   this.
  //   this.scene.dispatchEvent({type: INCREMENT_SCORE})
  // }
  private setPointerPosition(x: number, y: number) {
    this.pointerPosition.x = x
    this.pointerPosition.y = y
  }

  private setupPointerEvents() {
    document.addEventListener('mouseenter', e => {
      this.setPointerPosition(e.clientX, e.clientY)
    })
    document.addEventListener('mousemove', e => {
      this.setPointerPosition(e.clientX, e.clientY)
    })
    document.addEventListener('touchstart', e => {
      this.setPointerPosition(e.touches[0].clientX, e.touches[0].clientY)
    }, {passive: false});
    document.addEventListener('touchmove', e => {
      this.setPointerPosition(e.touches[0].clientX, e.touches[0].clientY)
    }, {passive: false});
  }

  private createMouseTrail() {
    this.scene.add(this.trail)
    const tween = new Tween({ value: 1 })
    tween.to({ value: 0 })
    tween.repeat(Infinity)
    tween.duration(10)
    tween.onRepeat(() => {
      const material = new MeshStandardMaterial()
      material.color = new Color(this.shapeColor)
      const geometry = new SphereGeometry(.03)
      const mesh = new Mesh(geometry, material)
      const mouse = new Vector2()
      mouse.x = (this.pointerPosition.x / this.renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -(this.pointerPosition.y / this.renderer.domElement.clientHeight) * 2 + 1;
      const vector = new Vector3(mouse.x, mouse.y, 0.5);
      vector.unproject( this.camera );
      const dir = vector.sub( this.camera.position ).normalize();
      const distance = - this.camera.position.z / dir.z;
      const pos = this.camera.position.clone().add( dir.multiplyScalar( distance ) );
      mesh.position.set(pos.x, pos.y, pos.z)
      this.animateMouseTrail(mesh)
    })
    tween.start()
    // const tween = new Tween({ alpha: 1 })

  }

  private animateMouseTrail(mesh: Mesh<SphereGeometry, MeshStandardMaterial>) {
    const tween = new Tween({ alpha: 1 })
    tween.to({ alpha: 0 })
    tween.duration(1000)
    tween.onUpdate(({ alpha }) => {
      mesh.scale.set(mesh.scale.x * alpha, mesh.scale.y * alpha, mesh.scale.z * alpha)
    })
    tween.onComplete(() => {
      mesh.parent?.remove(mesh)
    })
    tween.start()
    this.trail.add(mesh)
  }
}
