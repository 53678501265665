import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

export class LoaderService {
  private static instance: LoaderService;
  private loader = new GLTFLoader()

  static getInstance () {
    if (!LoaderService.instance) {
      LoaderService.instance = new LoaderService()
    }
    return LoaderService.instance
  }

  async getModel(path: string) {
    try {
      const model = await this.loader.loadAsync(path)
      return model
    } catch (e) {
      console.log(e)
      return null
    }
  }
}
